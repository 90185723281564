import { resolveMime } from 'friendly-mimes';
import { useState } from "react";

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PreviewIcon from '@mui/icons-material/Preview';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
// import SecondaryButton from "components/ui/buttons/Secondary";
import FileSizeSpan from "components/ui/FileSizeSpan";
import Loading from "components/ui/Loading";
import ModalDialog from "components/ui/ModalDIalog";
import RelativeDateFormatter from "components/ui/RelativeDateFormatter";
import NoResultsTableRow from "components/ui/tables/NoResultsTableRow";
import UserLink from "components/users/Link";
import Configuration from "Configuration";
import useDelete from "hooks/useDelete";
import secureApiFetch from "services/api";
import DownloadFromS3 from "services/DownloadFromS3";

const AttachmentsTable = ({ attachments, reloadAttachments }) => {

    const [modalVisible, setModalVisible] = useState(false);
    const [content, setContent] = useState(null);
    const onModalClose = () => {
        setModalVisible(false);
    }

    const deleteAttachmentById = useDelete('/attachments/', reloadAttachments);

    const onViewClick = (ev, attachmentId) => {
        ev.preventDefault();

        secureApiFetch(`/image/${attachmentId}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
            credentials: 'include' // Ensures cookies are sent with the request
        })
            .then(resp => {
                if (!resp.ok) {
                    throw new Error(`HTTP error! status: ${resp.status}`);
                }
                const contentDispositionHeader = resp.headers.get('Content-Disposition');
                const contentType = resp.headers.get('Content-Type');

                // Extract filename if it exists
                let filename = '';
                if (contentDispositionHeader) {
                    const filenameRe = new RegExp(/filename="(.+)"/);
                    const match = filenameRe.exec(contentDispositionHeader);
                    if (match && match[1]) {
                        filename = match[1];
                    }
                }

                return resp.blob().then(blob => ({ contentType, filename, blob }));
            })
            .then(({ contentType, filename, blob }) => {
                if (contentType.indexOf('image/') !== -1) {
                    const imgUrl = URL.createObjectURL(blob);
                    setContent(<img src={imgUrl} alt={filename} />);
                    // URL.revokeObjectURL(imgUrl);
                } else {
                    blob.text().then(text => {
                        setContent(<textarea style={{ width: '100%', height: '90%' }} value={text} readOnly />);
                    });
                }
                setModalVisible(true);
            })
            .catch(error => {
                console.error('Fetch error:', error);
            });
    }

    const onDownloadClick = (attachmentId) => {
        DownloadFromS3(attachmentId)
    }

    const onDeleteAttachmentClick = (ev, attachmentId) => {
        deleteAttachmentById(attachmentId)
            .then(() => reloadAttachments());
    }

    const safeResolveMime = mimeType => {
        try {
            return resolveMime(mimeType)['name']
        } catch (err) {
            console.error(err);
            return mimeType;
        }
    }

    if (!attachments) {
        return <Loading />
    }

    return <>
        <ModalDialog visible={modalVisible} title="Preview output" onModalClose={onModalClose} style={{ overflow: 'auto', width: '80%', height: '80%', maxHeight: '80%' }}>
            {content}
        </ModalDialog>
        <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded mt-2">
            <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                    <tr>

                        <th scope="col" className="px-4 py-2">Id</th>
                        <th scope="col" className="px-4 py-2 ">Filename</th>
                        <th scope="col" className="px-4 py-2">Mimetype</th>
                        <th scope="col" className="px-4 py-2">File size?</th>
                        <th scope="col" className="px-4 py-2">Upload date</th>
                        <th scope="col" className="px-4 py-2">Upload by</th>
                        <th scope="col" className="px-4 py-2 text-center">View</th>
                        <th scope="col" className="px-4 py-2 text-center">Download</th>
                        <th scope="col" className="px-4 py-2 text-center">Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {attachments.length === 0 && <NoResultsTableRow numColumns={9} />}
                    {attachments.map((attachment, index) => {
                        return (
                            <tr key={index} className="bg-white border-b even:bg-gray-50 border-gray-300 text-black font-light">

                                <th scope="row" className="px-4 py-1 ">
                                    {attachment.id}
                                </th>
                                <th scope="row" className="px-4 py-1 font-medium">
                                    <button onClick={() => { navigator.clipboard.writeText(`${Configuration.getDefaultApiUrl()}/image/${attachment.id}`) }} className="bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded hover:bg-gray-200 flex items-center">
                                        <ContentCopyIcon fontSize='small' />
                                        {attachment.client_file_name}
                                    </button>
                                </th>
                                <th scope="row" className="px-4 py-1 font-medium">
                                    <span title={safeResolveMime(attachment.file_mimetype)}>{attachment.file_mimetype}</span>
                                </th>

                                <th scope="row" className="px-4 py-1 font-medium">
                                    <FileSizeSpan fileSize={attachment.file_size} />
                                </th>
                                <th scope="row" className="px-4 py-1 font-medium">
                                    {attachment.insert_ts && <RelativeDateFormatter date={attachment.insert_ts} />}
                                </th>

                                <th scope="row" className="px-4 py-3 font-medium">
                                    <UserLink userId={attachment.submitter_uid}>{attachment.submitter_name}</UserLink>
                                </th>

                                <th scope="row" className="px-4 py-1 font-medium text-center">
                                    <button onClick={ev => onViewClick(ev, attachment.id)}>
                                        <PreviewIcon className='text-[#3A3D89]' />
                                    </button>
                                </th>
                                <th scope="row" className="px-4 py-1 font-medium text-center">
                                    <button onClick={ev => onDownloadClick(attachment.id)}>
                                        <DownloadIcon className='text-[#3A3D89]' />
                                    </button>
                                </th>
                                <th scope="row" className="px-4 py-1 font-medium text-center">
                                    <button onClick={ev => onDeleteAttachmentClick(ev, attachment.id)}>
                                        <DeleteIcon className='text-red-700' />
                                    </button>
                                </th>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    </>
}

export default AttachmentsTable;
