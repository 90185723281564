// import { Alert, AlertIcon } from '@chakra-ui/alert';
// import { UserManualUrl } from 'ServerUrls';
// import ExternalLink from 'components/ui/ExternalLink';
import { Link } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/hooks';
import { resolveMime } from 'friendly-mimes';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import PageTitle from 'components/logic/PageTitle';
import Breadcrumb from 'components/ui/Breadcrumb';
import EmptyField from 'components/ui/EmptyField';
import Loading from 'components/ui/Loading';
import NoResults from 'components/ui/NoResults';
import Title from 'components/ui/Title';
import useDelete from 'hooks/useDelete';
import useFetch from 'hooks/useFetch';
import DownloadFromS3 from 'services/DownloadFromS3';

import ReportModalDialog from './ModalDialog';


const ReportTemplatesList = () => {
    const [templates, refetchTemplates] = useFetch('/reports/templates')

    const destroy = useDelete('/reports/', refetchTemplates);

    const deleteTemplate = (ev, templateId) => {
        ev.stopPropagation();

        destroy(templateId);
    }

    const { isOpen: isAddReportTemplateDialogOpen, onOpen: openAddReportTemplateDialog, onClose: closeAddReportTemplateDialog } = useDisclosure();

    const onReportTemplateFormSaved = () => {
        refetchTemplates();
        closeAddReportTemplateDialog();
    }

    const handleDownload = (reportId) => {

        DownloadFromS3(reportId)
    }

    const safeResolveMime = mimeType => {
        try {
            return resolveMime(mimeType)['name']
        } catch (err) {
            console.error(err);
            return mimeType;
        }
    }
    return <>
        <PageTitle value="Report templates" />
        <div className='heading'>
            <Breadcrumb>
                <Link to="/reports">Reports</Link>
                <Link to="/reports/templates">Templates</Link>
            </Breadcrumb>

            <ReportModalDialog isOpen={isAddReportTemplateDialogOpen} onSubmit={onReportTemplateFormSaved} onCancel={closeAddReportTemplateDialog} />
            <button className='bg-[#3A3D89] px-2 py-1 rounded text-white space-x-1 flex items-center text-xs' onClick={openAddReportTemplateDialog}>
                <AddOutlinedIcon fontSize='small' /> Add Report Template
            </button>
        </div>
        <Title title='Report templates' icon={<DocumentScannerOutlinedIcon />} />

        {/* <Alert status="info">
            <AlertIcon />
            Needing some inspiration? Have a look at hundred of penetration test reports available at&nbsp;<ExternalLink href="http://pentestreports.com/">http://pentestreports.com/</ExternalLink>
        </Alert>

        <Alert status="info">
            <AlertIcon />
            Visit this <ExternalLink href={UserManualUrl + 'reports/report-template-variables.html'}>user manual's page</ExternalLink> if you want to find out which variables are available to your report templates.
        </Alert> */}

        {!templates ? <Loading /> :
            <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
                <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                    <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                        <tr>
                            <th scope="col" className="px-4 py-2">Name</th>
                            <th scope="col" className="px-4 py-2">Description</th>
                            <th scope="col" className="px-4 py-2">File Name</th>
                            <th scope="col" className="px-4 py-2">Mime type</th>
                            <th scope="col" className="px-4 py-2">Download</th>
                            <th scope="col" className="px-4 py-2">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {templates.length === 0 ?
                            <tr><th className="px-4 py-4" colSpan={6}><NoResults /></th></tr>
                            :
                            templates.map((template, index) =>
                                <tr key={index} className="bg-white border-b border-gray-300 text-gray-800">

                                    <th scope="row" className="px-3 py-2 font-medium">
                                        {template.version_name}
                                    </th>

                                    <th scope="row" className="px-3 py-2 font-medium">
                                        <EmptyField value={template.version_description} />
                                    </th>

                                    <th scope="row" className="px-3 py-2 font-medium">
                                        {template.client_file_name}
                                    </th>

                                    <th scope="row" className="px-3 py-2 font-medium">
                                        <span title={safeResolveMime(template.file_mimetype)}>{template.file_mimetype}</span>
                                    </th>

                                    <th scope="row" className="px-3 py-2">
                                        <button onClick={() => handleDownload(template.attachment_id)} className="bg-[#3A3D89] px-2 py-1 rounded text-white text-xs font-light flex items-center">
                                            {template.file_mimetype !== "application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? 'Technical' : 'Executive'}
                                            <FileDownloadOutlinedIcon fontSize='small' className='font-light ml-1' />
                                        </button>
                                    </th>

                                    <th scope="row" className="px-3 py-2">
                                        <button disabled={template.generated_by_uid === 0} onClick={ev => deleteTemplate(ev, template.id)}>
                                            <DeleteIcon fontSize='small' className='text-[#EB1F36]' />
                                        </button>
                                    </th>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>}
    </>
}

export default ReportTemplatesList;
