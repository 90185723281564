import { Link } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import RestrictedComponent from 'components/logic/RestrictedComponent';
import LoadingTableRow from 'components/ui/tables/LoadingTableRow';
import NoResultsTableRow from 'components/ui/tables/NoResultsTableRow';

import ClientLink from "../clients/Link";
import ProjectBadge from './ProjectBadge';


const ProjectsTable = ({ tableModel, tableModelSetter: setTableModel, reloadCallback, showSelection = true, showProjectColumn = true, showClientColumn = true, destroy }) => {
    const numColumns = showClientColumn ? 7 : 6;
    const getStatusColor = (status) => {
        const statusMappings = {
           'To Start': 'bg-yellow-100 text-yellow-800 border border-yellow-300',
            'In Progress': 'bg-green-100 text-green-800 border border-green-300',
            'On Hold': 'bg-pink-100 text-pink-800 border border-pink-300',
            'Completed': 'bg-blue-100 text-blue-800 border border-blue-300',
        };

        return statusMappings[status] || 'bg-gray-100 text-gray-800';
    };
    return (
        <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
            <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300"> 
                    <tr>
                        <th scope="col" className="px-4 py-2">Name</th>
                        <th scope="col" className="px-2 py-2">Client</th>
                        <th scope="col" className="px-2 py-2">Category</th>
                        <th scope="col" className="px-2 py-2">Status</th>
                        <th scope="col" className="px-2 py-2">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {null === tableModel.projects && <LoadingTableRow numColumns={numColumns} />}
                    {null !== tableModel.projects && 0 === tableModel.projects.length && <NoResultsTableRow numColumns={numColumns} />}
                    {null !== tableModel.projects && 0 !== tableModel.projects.length && tableModel.projects.map((project, index) => {
                        return (
                            <tr key={index} className="bg-white border-b border-gray-300 text-black font-light">

                                <th scope="row" className="px-2 py-2 ">
                                    <ProjectBadge project={project} />
                                    <div className='mt-[4px]'>
                                        <span className="bg-blue-100 text-blue-800 text-[9px] font-medium me-2 px-2.5 py-0.5 rounded  border border-blue-400 ">
                                            {project.vulnerability_metrics ? project.vulnerability_metrics : '(undefined)'}</span>
                                        {project.archived === 1 ?
                                            <span className="px-5 py-1 font-medium bg-[#EB1F36] text-white rounded text-xs">
                                                Archived
                                            </span>
                                            : <></>
                                        }
                                    </div>
                                </th>

                                {showClientColumn &&
                                    <th className='px-2 py-1'>{project.is_template ?
                                        <span title="Not applicable">(n/a)</span> :
                                        <ClientLink clientId={project.client_id}>{project.client_name}</ClientLink>}
                                    </th>
                                }

                                <th scope="row" className="px-2 py-1 font-medium">
                                    {project.category_id !== null ? project.category_name : '(undefined)'}
                                </th>

                                <th scope="row" className="px-2 py-1">
                                    <span className={`text-xs font-medium inline-flex px-2 py-0.5 rounded  ${getStatusColor(project.project_status)}`}>
                                        {project.project_status || '(undefined)'}
                                    </span>
                                </th>
                                <th scope="row" className="px-2 py-1 ">
                                    <RestrictedComponent roles={['administrator', 'superuser', 'user']}>
                                        <Link to={`/projects/${project.id}/edit`} >
                                            <ModeEditIcon fontSize='small' className='text-[#3A3D89]' />
                                        </Link>
                                        {destroy &&
                                            <button onClick={() => destroy(project.id)}>
                                                <DeleteIcon fontSize='small' className='text-red-600 mx-1' />
                                            </button>
                                        }
                                    </RestrictedComponent>
                                </th>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default ProjectsTable;
