import React from 'react';
import { Link } from 'react-router-dom';
import { Checkbox } from '@chakra-ui/react';

import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import RestrictedComponent from "components/logic/RestrictedComponent";
import ProjectBadge from "components/projects/ProjectBadge";
import LoadingTableRow from 'components/ui/tables/LoadingTableRow';
import NoResultsTableRow from 'components/ui/tables/NoResultsTableRow';
import Markdown from 'react-markdown'

import TaskStatusFormatter from "./TaskStatusFormatter";
import BadgeOutline from '../badges/BadgeOutline';
import UserLink from "../users/Link";
import TaskBadge from "./TaskBadge";


const TasksTable = ({ tableModel, tableModelSetter: setTableModel, destroy, reloadCallback = null }) => {
    const showSelection = tableModel.columnsVisibility.selection;
    const showProjectColumn = tableModel.columnsVisibility.project;
    const numColumns = 6 + (showSelection ? 1 : 0) + (showProjectColumn ? 1 : 0);

    const onSelectionChange = ev => {
        const target = ev.target;
        const selectionId = parseInt(target.value);
        if (target.checked) {
            setTableModel({ ...tableModel, selection: [...tableModel.selection, selectionId] })
        } else {
            setTableModel({ ...tableModel, selection: tableModel.selection.filter(value => value !== selectionId) })
        }
    };

    const onHeaderCheckboxClick = ev => {
        if (ev.target.checked) {
            setTableModel({ ...tableModel, selection: tableModel.tasks.map(task => task.id) })

        } else {
            setTableModel({ ...tableModel, selection: [] })
        }
    }
    const tasksLength = null !== tableModel.tasks ? tableModel.tasks.length : 0;

    return (
        <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
            <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                    <tr>
                        {showSelection &&
                            <th scope="col" className="px-2 py-2">
                                <Checkbox borderColor='gray.300' onChange={onHeaderCheckboxClick} isChecked={tableModel.selection.length && tableModel.selection.length === tasksLength} isDisabled={tableModel.tasksLength === 0} />
                            </th>
                        }
                        <th scope="col" className="px-2 py-2">SUMMARY</th>
                        <th scope="col" className="px-2 py-2"> DESCRIPTION</th>
                        {showProjectColumn &&
                            <th scope="col" className="px-2 py-2">Project</th>
                        }
                        <th scope="col" className="px-2 py-2">PRIORITY</th>
                        <th scope="col" className="px-2 py-2"> ASSIGNEE </th>
                        <th scope="col" className="px-2 py-2">STATUS</th>
                        <th scope="col" className="px-2 py-2">COMMAND</th>
                        <th scope="col" className="px-2 py-2">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {null === tableModel.tasks && <LoadingTableRow numColumns={numColumns} />}
                    {null !== tableModel.tasks && 0 === tableModel.tasks.length && <NoResultsTableRow numColumns={10} />}
                    {null !== tableModel.tasks && tableModel.tasks.map(task => (
                        <React.Fragment key={task.id}>
                            <tr className="border-b border-gray-300">
                                {showSelection &&
                                    <td className="px-2 py-1">
                                        <Checkbox borderColor='gray.400' value={task.id} onChange={onSelectionChange} isChecked={tableModel.selection.includes(task.id)}></Checkbox>
                                    </td>
                                }

                                <td className="px-2 py-1">
                                    <TaskBadge task={task} />
                                    {task.maintask && (
                                        <span className='block'>
                                            <span className='bg-blue-100 text-blue-800 text-[9px] font-medium me-2 px-2.5 py-0.5 rounded  border border-blue-400'>Sub-Task</span>
                                            <Link className='bg-blue-100 text-blue-800 text-[9px] font-medium me-2 px-2.5 py-0.5 rounded  border border-blue-400' to={"/tasks/" + task.maintask}>{task.maintask}</Link>
                                        </span>
                                    )}
                                </td>

                                <td className="px-2 ">
                                    <Markdown>
                                        {task.description ? task.description.substring(0, 100) + "..." : "-"}
                                    </Markdown>
                                </td>

                                {showProjectColumn && <td className="px-4 py-1"><ProjectBadge project={{ id: task.project_id, name: task.project_name }} /></td>}

                                <td className="px-2 py-1">{task.priority}</td>

                                <td className="px-2 py-1">{task.assignee_uid ? <UserLink userId={task.assignee_uid}>{task.assignee_full_name}</UserLink> : '(nobody)'}  </td>

                                <td className="px-2 py-1"> <TaskStatusFormatter task={task} /> </td>

                                <td className="px-2 py-1"> {task.command_name ? <BadgeOutline id={task.command_id}>{task.command_name}</BadgeOutline> : '-'} </td>

                                <td className="px-2 py-1">
                                    <RestrictedComponent roles={['administrator', 'superuser', 'user']}>
                                        <span className='flex space-x-1'>
                                            <Link to={`/tasks/${task.id}/edit`}> <ModeEditIcon className='text-[#3A3D89]' fontSize='small' /> </Link>
                                            {destroy && <button onClick={() => destroy(task.id)}>
                                                <DeleteIcon className='text-[#EB1F36]' fontSize='small' />
                                            </button>}
                                        </span>
                                    </RestrictedComponent>
                                </td>
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default TasksTable;
