import Configuration from "Configuration";
import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import { useParams } from 'react-router-dom';
import secureApiFetch from "services/api";

export default function ImageCopy({ name: editorName,value, onChange: onFormChange, parentType, parentNameId }) {
    const [selectedTab, setSelectedTab] = React.useState("write");
    const { vulnerabilityId } = useParams();

    useEffect(() => {
    }, [parentNameId])

    const saveImage = async function* (data, fileName) {
        const wait = function (time) {
            return new Promise((resolve) => setTimeout(resolve, time));
        };

        // Create a new formData for each image
        const formData = new FormData();
        formData.append('parentType', parentType);
        if (vulnerabilityId === undefined) {
            formData.append('parentId', parentNameId);
        } else {
            formData.append('parentId', vulnerabilityId);
        }

        const blobData = new Blob([data]);
        formData.append('attachment[]', blobData, fileName.name);

        let codeApi;
        try {
            const resp = await secureApiFetch(`/attachments`, {
                method: 'POST',
                body: formData
            });
            const responseData = await resp.json();
            codeApi = responseData[0].id;
        } catch (err) {
            console.error(err);
        }

        await wait(3000);
        yield `${Configuration.getDefaultApiUrl()}/image/${codeApi}`;
    };

    return (
        <div className="container">
            <ReactMde
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                value={value}
                onChange={editorValue => onFormChange({ target: { name: editorName, value: editorValue } })}
                generateMarkdownPreview={(markdown) =>
                    Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                }
                childProps={{
                    writeButton: {
                        tabIndex: -1
                    }
                }}
                paste={{
                    saveImage: saveImage
                }}
            />
        </div>
    );
}
