import { Flex, HStack } from '@chakra-ui/react';
import { actionCompletedToast } from 'components/ui/toast';
import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import PageTitle from 'components/logic/PageTitle';
import RestrictedComponent from 'components/logic/RestrictedComponent';
import secureApiFetch from 'services/api';
import useQuery from 'hooks/useQuery';
import Pagination from 'components/layout/Pagination';

import useDelete from '../../hooks/useDelete';
import TaskStatuses from "../../models/TaskStatuses";
import Breadcrumb from '../ui/Breadcrumb';
import CreateButton from "../ui/buttons/Create";
import Title from '../ui/Title';
import TaskFilters from './Filters';
import TasksTable from './TasksTable';
import TaskTableModel from './TaskTableModel';

const TasksList = () => {
    const navigate = useNavigate();
    const query = useQuery();
    let pageNumber = query.get('page');
    pageNumber = pageNumber !== null ? parseInt(pageNumber) : 1;
    const apiPageNumber = pageNumber - 1;
    const [numberPages, setNumberPages] = useState(1);
    const [totalCount, setTotalCount] = useState('?');
    const [tableModel, setTableModel] = useState(new TaskTableModel(true, true))

    const handleCreateTask = () => {
        navigate(`/tasks/create`);
    }

    const handlePrev = () => {
        const queryParams = new URLSearchParams();
        queryParams.set('isTemplate', 'false');
        queryParams.set('page', pageNumber - 1);
        queryParams.set('orderColumn', tableModel.sortBy.column);
        queryParams.set('orderDirection', tableModel.sortBy.order);
        Object.keys(tableModel.filters)
            .forEach(key => tableModel.filters[key] !== null && tableModel.filters[key].length !== 0 && queryParams.append(key, tableModel.filters[key]));
        const url = `/tasks?${queryParams.toString()}`;
        navigate(url);
    }

    const handleNext = () => {
        const queryParams = new URLSearchParams();
        queryParams.set('isTemplate', 'false');
        queryParams.set('page', pageNumber + 1);
        queryParams.set('orderColumn', tableModel.sortBy.column);
        queryParams.set('orderDirection', tableModel.sortBy.order);
        Object.keys(tableModel.filters)
            .forEach(key => tableModel.filters[key] !== null && tableModel.filters[key].length !== 0 && queryParams.append(key, tableModel.filters[key]));
        const url = `/tasks?${queryParams.toString()}`;
        navigate(url);
    }

    const reloadTasks = useCallback(() => {
        setTableModel(tableModel => ({ ...tableModel, tasks: null }));
        const queryParams = new URLSearchParams();
        queryParams.set('page', apiPageNumber);
        queryParams.set('orderColumn', tableModel.sortBy.column);
        queryParams.set('orderDirection', tableModel.sortBy.order);
        Object.keys(tableModel.filters)
            .forEach(key => tableModel.filters[key] !== null && tableModel.filters[key].length !== 0 && queryParams.append(key, tableModel.filters[key]));
        const url = `/tasks?${queryParams.toString()}`;

        secureApiFetch(url, { method: 'GET' })
            .then(resp => {
                if (resp.headers.has('X-Page-Count')) {
                    setNumberPages(resp.headers.get('X-Page-Count'))
                }
                if (resp.headers.has('X-Total-Count')) {
                    setTotalCount(resp.headers.get('X-Total-Count'))
                }
                return resp.json()
            })
            .then(data => {
                setTableModel(tableModel => ({ ...tableModel, tasks: data }));
            });
    }, [setTableModel, apiPageNumber, tableModel.filters, tableModel.sortBy.column, tableModel.sortBy.order]);

    const onStatusSelectChange = (ev) => {
        const newStatus = ev.target.value;
        secureApiFetch('/tasks', {
            method: 'PATCH',
            headers: {
                'Bulk-Operation': 'UPDATE',
            },
            body: JSON.stringify({
                taskIds: tableModel.selection,
                newStatus: newStatus
            })
        })
            .then(reloadTasks)
            .then(() => {
                actionCompletedToast(`All selected tasks have been transitioned to "${newStatus}".`);
                ev.target.value = '';
            })
            .catch(err => console.error(err));
    }

    const onDeleteButtonClick = () => {
        secureApiFetch('/tasks', {
            method: 'PATCH',
            headers: {
                'Bulk-Operation': 'DELETE',
            },
            body: JSON.stringify({
                taskIds: tableModel.selection
            }),
        })
            .then(reloadTasks)
            .then(() => {
                setTableModel({ ...tableModel, selection: [] })
                actionCompletedToast('All selected tasks were deleted.');
            })
            .catch(err => console.error(err));
    };

    const destroy = useDelete('/tasks/', reloadTasks);

    useEffect(() => {
        reloadTasks()
    }, [reloadTasks, tableModel.filters])

    return <>
        <PageTitle value="Tasks" />
        <div className='heading'>
            <Breadcrumb>
                <Link to="/projects">Projects</Link>
                <Link to="/tasks">Task</Link>
            </Breadcrumb>
            <Pagination page={apiPageNumber} total={numberPages} handlePrev={handlePrev} handleNext={handleNext} />
            <HStack alignItems='flex-end'>
                <RestrictedComponent roles={['administrator', 'superuser', 'user']}>
                    <CreateButton onClick={handleCreateTask}>Create Task</CreateButton>
                </RestrictedComponent>

                <div>
                    <select disabled={!tableModel.selection.length} onChange={onStatusSelectChange} className='px-2 py-1 border rounded text-sm w-36 font-medium'>
                        <option value="">(select option)</option>
                        {TaskStatuses.map((status, index) =>
                            <option key={index} value={status.id}>{status.name}</option>
                        )}
                    </select>
                </div>

                <RestrictedComponent roles={['administrator']}>
                    <button disabled={!tableModel.selection.length} onClick={onDeleteButtonClick} className='bg-[#EB1F36] px-2 py-1.5 rounded text-white space-x-1 flex items-center text-sm'>
                        <DeleteIcon className='text-white' fontSize='small' /> Delete Selected
                    </button>
                </RestrictedComponent>
            </HStack>
        </div>
        <Title title={`Tasks (${totalCount})`} icon={<FolderOpenOutlinedIcon />} />

        <Flex >
            <TaskFilters tableModel={tableModel} tableModelSetter={setTableModel} />
        </Flex>

        <TasksTable tableModel={tableModel} tableModelSetter={setTableModel} destroy={destroy} reloadCallback={reloadTasks} />
    </>
}

export default TasksList
