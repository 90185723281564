// import Configuration from "Configuration";


const HeaderLogo = () => {

    return <h3>
        <img alt="Prosecurity Logo" src={`https://clients.prosecurity.work/layout/images/logo.svg`} />
    </h3>
}

export default HeaderLogo;
