import React, { useState, useEffect, useCallback } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { ButtonGroup, IconButton, Menu, MenuButton, MenuItem, MenuList, Tab, TabList, TabPanel, TabPanels, Tabs, useColorMode, Switch } from "@chakra-ui/react";
import { faEllipsis, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { actionCompletedToast, errorToast } from "components/ui/toast";

import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import PageTitle from "components/logic/PageTitle";
import RestrictedComponent from "components/logic/RestrictedComponent";
import secureApiFetch from "services/api";
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';

import ProjectVulnerabilities from './Vulnerabilities';
import ProjectAttachmentsTab from './AttachmentsTab';
import ProjectVaultTab from './vault/VaultTab';
import useDelete from '../../hooks/useDelete';
import ProjectDetailsTab from './DetailsTab';
import useFetch from '../../hooks/useFetch';
import Breadcrumb from '../ui/Breadcrumb';
import ProjectNotesTab from "./NotesTab";
import ProjectTargets from './Targets';
import Loading from '../ui/Loading';
import ProjectTasks from './Tasks';
import ProjectTeam from './Team';
import Title from '../ui/Title';

const ProjectDetails = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const { colorMode } = useColorMode();
    const [userAccess, setUserAccess] = useState(false)
    const [project, updateProject] = useFetch(`/projects/${projectId}`);
    const [users] = useFetch(`/projects/${projectId}/users`);
    const destroy = useDelete(`/projects/`, updateProject);

    const getUserAccess = useCallback(() => {
        secureApiFetch(`/notes?parentType=project&parentId=${projectId}`, {
            method: 'GET',
        })
            .then((res) => {
                if (res.status === 403) {
                    errorToast('You have limited access of the project.')
                }
                if (res.status !== 403) {
                    setUserAccess(true)
                }
            })
            .catch(err => console.error(err));
    }, [projectId])

    useEffect(() => {
        getUserAccess()
    }, [getUserAccess])

    const handleGenerateReport = () => {
        navigate(`/projects/${project.id}/report`);
    };
    const handleManageTeam = () => {
        navigate(`/projects/${project.id}/membership/${project.client_id}`);
    };
    const onArchiveButtonClick = (project) => {
        secureApiFetch(`/projects/${project.id}`, {
            method: 'PATCH',
            body: JSON.stringify({ archived: !project.archived })
        })
            .then(() => {
                updateProject();
                actionCompletedToast('The project has been updated.');
            })
            .catch(err => console.error(err));
    };
    const handleVisibilty = () => {
        const newVisibilty = project.visibility !== 'public' ? 'public' : 'private'
        secureApiFetch(`/projects/${project.id}`, {
            method: 'PATCH',
            body: JSON.stringify({ visibility: newVisibilty })
        })
            .then(() => {
                updateProject();
                actionCompletedToast('Visibilty has been updated')
            })
            .cactch((err) => {
                console.log(err)
            })
    }
    const handleChangeStatus = (event) => {
        const selectedValueStatus = event.target.value
        const statusValue = selectedValueStatus.split(":")
        secureApiFetch(`/projects/${project.id}`, {
            method: 'PATCH',
            body: JSON.stringify({ project_status: statusValue[0], project_substatus: statusValue[1] })
        })
            .then(() => {
                updateProject();
                actionCompletedToast('The project has been updated.');
            })
            .catch(err => console.error(err));
    }
    const getStatusColor = (status) => {
        const statusMappings = {
            'To Start': 'bg-yellow-100 text-yellow-800 border border-yellow-300',
            'In Progress': 'bg-green-100 text-green-800 border border-green-300',
            'On Hold': 'bg-pink-100 text-pink-800 border border-pink-300',
            'Completed': 'bg-blue-100 text-blue-800 border border-blue-300',
        };

        return statusMappings[status] || 'bg-gray-100 text-gray-800';
    };
    const project_status = [
        'To Start:Onboarding And Initial Setup',
        'To Start:Testing',
        'In Progress:Testing',
        'In Progress:Remediation',
        'In Progress:Retest',
        'In Progress:QA And Reporting',
        'On Hold:Outside Time Window',
        'On Hold:Connectivity',
        'On Hold:Credentials',
        'On Hold:With Customer',
        'On Hold:With Assessor',
        'Completed:Final Report Released',
        'Completed:Archived'
    ];
    if (project && project.is_template) {
        return <Navigate to={`/projects/templates/${project.id}`} />;
    }

    return (
        <>
            <div className='heading'>
                <Breadcrumb>
                    <Link to="/projects">Projects</Link>
                </Breadcrumb>
                {project && (
                    <>
                        <PageTitle value={`${project.name} project`} />
                        <ProjectTeam project={project} users={users} />

                        <ButtonGroup isAttached>
                            <RestrictedComponent roles={['administrator', 'superuser', 'user']}>
                                <div className='flex items-center space-x-1 mx-1'>

                                    <button onClick={handleGenerateReport} className='bg-[#3A3D89] px-2 py-1.5 rounded text-white space-x-1 flex items-center text-sm'>
                                        Report
                                    </button>
                                    <RestrictedComponent roles={['administrator', 'superuser']}>
                                        <button onClick={handleManageTeam} className='bg-[#3A3D89] px-2 py-1.5 rounded text-white space-x-1 flex items-center text-sm'>
                                            Membership
                                        </button>
                                        <Link to={`/projects/${project.id}/edit`} >
                                            <button onClick={handleManageTeam} className='bg-[#3A3D89] px-2 py-1.5 rounded text-white space-x-1 flex items-center text-sm'>
                                                Edit
                                            </button>
                                        </Link>
                                    </RestrictedComponent>

                                    <div className="border px-2 py-1.5 rounded flex items-center">
                                        <label className='font-medium'>
                                            {project.visibility === 'public' ? 'Visible' : 'Hidden'}
                                        </label>
                                        <Switch isChecked={project.visibility === 'public'} onChange={handleVisibilty} />
                                    </div>

                                    <select onChange={handleChangeStatus} className='px-2 py-1.5 border rounded text-sm w-36 font-medium'>
                                        <option>Change Status</option>
                                        {project_status.map(status => (
                                            <option key={status} value={status}>
                                                {status.replace(/_/g, ' ')}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <Menu>
                                    <MenuButton as={IconButton} aria-label='Options' icon={<FontAwesomeIcon icon={faEllipsis} />} variant='outline' />
                                    <MenuList>
                                        <MenuItem icon={<ArchiveOutlinedIcon sx={{ color: '#3A3D89' }} />} onClick={() => onArchiveButtonClick(project)}>{project.archived ? 'Unarchive' : 'Archive'}</MenuItem>
                                        <MenuItem icon={<FontAwesomeIcon icon={faTrash} />} color={colorMode === "light" ? "red.500" : "red.400"} onClick={() => destroy(project.id)}>Delete</MenuItem>
                                    </MenuList>
                                </Menu>
                            </RestrictedComponent>
                        </ButtonGroup>
                    </>
                )}
            </div>
            {!project ? <Loading /> :
                <>
                    <Title title={project.name} type="Project" icon={<FolderOpenOutlinedIcon />} />
                    <div className='flex items-center space-x-2 my-2'>
                        {project.visibility === 'private' ? <span className="bg-red-500 text-white text-center text-xs font-medium me-2 px-2.5 py-1 rounded">
                            Private
                        </span> : <span className="bg-[#48A14D] text-white text-center text-xs font-medium me-2 px-2.5 py-1 rounded">
                            Published
                        </span>
                        }
                        {project.external_id &&
                            <span className="px-5 py-1 font-medium bg-blue-50 text-blue-500 border border-blue-200 rounded text-xs">
                                {project.external_id}
                            </span>
                        }

                        {project.project_status &&
                            <span className={`text-xs font-medium px-2 py-1 rounded  ${getStatusColor(project.project_status)}`}>
                                {project.project_status || '(undefined)'}: {project.project_substatus || '(undefined)'}
                            </span>
                        }
                        {project.vulnerability_metrics &&
                            <span className="px-5 py-1 font-medium bg-blue-50 border border-blue-200 text-blue-500 rounded text-xs">
                                {project.vulnerability_metrics}
                            </span>
                        }
                        {project.category_name &&
                            <span className="px-5 py-1 font-medium bg-blue-50 border border-blue-200 text-blue-500 rounded text-xs">
                                {project.category_name}
                            </span>
                        }
                        {project.archived === 1 ?
                            <span className="px-5 py-1 font-medium bg-[#EB1F36] text-white rounded text-xs">
                                Archived
                            </span>
                            : <></>
                        }
                    </div>
                    <Tabs variant='enclosed'>
                        <TabList>
                            <Tab> <span className='text-xs font-medium'>Details</span> </Tab>
                            {userAccess && (
                                <>
                                    <Tab> <span className='text-xs font-medium'>Targets</span> </Tab>
                                    <Tab> <span className='text-xs font-medium'>Tasks</span> </Tab>
                                    <Tab> <span className='text-xs font-medium'>Vulnerabilities</span> </Tab>
                                    <Tab> <span className='text-xs font-medium'>Discussion</span> </Tab>
                                    <Tab> <span className='text-xs font-medium'>Attachments</span> </Tab>
                                    <Tab> <span className='text-xs font-medium'>Vault</span> </Tab>
                                </>
                            )}

                        </TabList>
                        <TabPanels>
                            <TabPanel><ProjectDetailsTab project={project} /></TabPanel>
                            <TabPanel><ProjectTargets project={project} /></TabPanel>
                            <TabPanel><ProjectTasks project={project} /></TabPanel>
                            <TabPanel><ProjectVulnerabilities project={project} /></TabPanel>
                            <TabPanel><ProjectNotesTab project={project} /></TabPanel>
                            <TabPanel><ProjectAttachmentsTab project={project} /></TabPanel>
                            <TabPanel><ProjectVaultTab project={project} /></TabPanel>
                        </TabPanels>
                    </Tabs>
                </>
            }
        </>
    );
};

export default ProjectDetails;
