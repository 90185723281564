import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import './Link.css';

const UserLink = ({ userId, children }) => {
    return (
        <Link className="px-1.5 py-1.5 border rounded inline-flex text-sm" to={`/users/${userId}`}>
            <PersonOutlineOutlinedIcon fontSize='small'  />
            <span className={`${children === 'Administrator' ? 'text-red-700' : ''}`}>
                {children}
            </span>
        </Link>
    )
}

UserLink.propTypes = {
    userId: PropTypes.any.isRequired
};

export default UserLink;
