import { Link } from "react-router-dom";

import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import TimestampsSection from "components/ui/TimestampsSection";
import ProjectBadge from "components/projects/ProjectBadge";
import VulnerabilityCategorySpan from "./categories/Span";
import TargetBadge from "components/target/TargetBadge";
import EmptyField from "components/ui/EmptyField";
import UserLink from "components/users/Link";
import ReactMarkdown from "react-markdown";
import ReactMdeMark from "components/ui/forms/ReactMdeMark";


const VulnerabilityDescriptionPanel = ({ vulnerability }) => {
    const owaspLikehoodNumeric = parseFloat(vulnerability.owasp_likehood);
    const owaspImpactNumeric = parseFloat(vulnerability.owasp_impact);

    // Check if both owasp_likehood (numeric) and owasp_impact (numeric) are greater than 0
    const showPropertiesSection = owaspLikehoodNumeric > 0 || owaspImpactNumeric > 0;
    return (
        <div className="grid grid-two">
            <div>
                <dl>
                    <h4 className='text-sm font-medium'>
                        <DescriptionOutlinedIcon sx={{ color: '#3A3D89' }} /> Vulnerability Description
                    </h4>
                    <dt>Description</dt>
                    <dd>{vulnerability.description
                        ? <ReactMdeMark>{vulnerability.description}</ReactMdeMark>
                        : <EmptyField />}</dd>

                    <dt>  Impact  </dt>
                    <dd>  {vulnerability.impact
                        ? <ReactMdeMark>{vulnerability.impact}</ReactMdeMark>
                        : <EmptyField />}  </dd>

                    <dt> Category </dt>
                    <dd> <VulnerabilityCategorySpan name={vulnerability.category_name} parentName={vulnerability.parent_category_name} /> </dd>

                    <dt> External reference   </dt>
                    <dd>
                        {vulnerability.external_refs
                            ? <ReactMdeMark >{vulnerability.external_refs}</ReactMdeMark>
                            : <EmptyField />
                        }
                    </dd>

                    <dt> Proof of concept   </dt>
                    <dd>
                        {vulnerability.proof_of_concept
                            ? <ReactMdeMark>{vulnerability.proof_of_concept}</ReactMdeMark>
                            : <EmptyField />
                        }
                    </dd>
                </dl>

                {showPropertiesSection && (
                    <div>
                        <h4 className="font-bold">Properties</h4>
                        <dl>

                            {vulnerability.owasp_vector && <>
                                <dt>OWASP vector</dt>
                                <dd className="text-base">{vulnerability.owasp_vector}
                                </dd>
                            </>}
                            {vulnerability.owasp_overall && <>
                                <dt>OWASP overall score</dt>
                                <dd className="text-base">{vulnerability.owasp_overall}
                                </dd>
                            </>}
                            {vulnerability.owasp_likehood && <>
                                <dt>OWASP likehood score</dt>
                                <dd className="text-base">{vulnerability.owasp_likehood}
                                </dd>
                            </>}
                            {vulnerability.owasp_impact && <>
                                <dt>OWASP impact score</dt>
                                <dd className="text-base">{vulnerability.owasp_impact}
                                </dd>
                            </>}
                        </dl>
                    </div>
                )}
            </div>

            <div>
                <h4 className="text-sm font-medium">
                    <InsertLinkOutlinedIcon sx={{ color: '#3A3D89' }} /> Relations
                </h4>
                <dl>
                    {!vulnerability.is_template && <>
                        <dt>Project</dt>
                        <dd>{vulnerability.project_id ?
                            <ProjectBadge project={{ id: vulnerability.project_id, name: vulnerability.project_name }} /> : '-'}</dd>
                    </>}
                    <dt> Affected Resource   </dt>
                    <dd>  {vulnerability.affected_resource ? <ReactMarkdown>{vulnerability.affected_resource}</ReactMarkdown> : <EmptyField />}  </dd>
                    {vulnerability.target_id !== null && vulnerability.target_id !== 0 && <>
                        <dt>Affected target</dt>
                        <dd><Link to={`/targets/${vulnerability.target_id}`}><TargetBadge name={vulnerability.target_name}>{vulnerability.target_id ? `${vulnerability.target_name} (${vulnerability.target_kind})` : "-"}</TargetBadge></Link></dd>
                    </>}

                    <dt>Created by</dt>
                    <dd><UserLink userId={vulnerability.creator_uid}>{vulnerability.creator_full_name}</UserLink></dd>
                </dl>

                <TimestampsSection entity={vulnerability} />
            </div>
        </div>
    )
}

export default VulnerabilityDescriptionPanel;
