import React, { useState } from 'react'
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { actionCompletedToast, errorToast } from 'components/ui/toast';
import secureApiFetch from 'services/api';
import Papa from 'papaparse';
import CSVDataFile from './CSVDataFile';
import ProcessingLogo from '../ui/process logo/ProcessingLogo'

const UploadTargets = ({ reloadTargets, project, isOpen, onClose }) => {
    const [csvData, setCsvData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false);

    const handleDownload = () => {
        const decodedString = atob(CSVDataFile);
        const blob = new Blob([decodedString], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'sample.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            Papa.parse(file, {
                header: true,
                complete: (results) => {
                    const updatedData = results.data.map(entry => ({
                        ...entry,
                        project_id: project.id
                    }));
                    setCsvData(updatedData);
                },
                error: (error) => {
                    console.error('Error parsing CSV file:', error);
                }
            });
        }
    };
    
    const handleCsvSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        setDisabled(true);
        const newDataArray = csvData
            .filter(item => item.Name && item.Tags !== undefined)
            .map(item => ({
                ...item,
                Kind: item.Kind ? item.Kind.toLowerCase() : '',
                Tags: item.Tags ? (Array.isArray(item.Tags) ? item.Tags : [item.Tags]) : [],
            }));
        const newData = newDataArray.map(item => ({
            ...item,
            Tags: item.Tags ? JSON.stringify(item.Tags) : '[]'
        }));
        try {
            const requests = newData.map((csvData) => {
                return secureApiFetch(`/targets`, {
                    method: 'POST',
                    body: JSON.stringify(csvData)
                });
            });
            const responses = await Promise.allSettled(requests);
            let successCount = 0;
            let errorCount = 0;
            reloadTargets()
            onClose()
            setCsvData([])
            responses.forEach(response => {
                if (response.status === 'fulfilled' && response.value.status === 201) {
                    successCount++;
                } else if (response.status === 'fulfilled' && response.value.status === 400) {
                    errorCount++;
                } else if (response.status === 'rejected') {
                    errorCount++;
                }
            });
            setLoading(false)
            if (successCount > 0) {
                actionCompletedToast(`${successCount} target(s) have been uploaded.`);
            }

            if (errorCount > 0) {
                errorToast(`${errorCount} target(s) encountered errors. Please check the data and try again.`);
            }
            setTimeout(() => setDisabled(false), 2000)
        } catch (error) {
            console.error('Error processing data:', error);
            errorToast('An unexpected error occurred. Please try again.');
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}><ModalOverlay />
            <ModalContent>
                <ModalHeader>Upload targets</ModalHeader><ModalCloseButton />
                <ModalBody>
                    <div className='flex justify-between items-center'>
                        <label className="block mb-2 text-sm font-medium text-gray-900 " for="file_input">Upload file</label>
                        <button onClick={handleDownload} className='bg-[#3A3D89] px-2 py-0.5 mx-2 rounded text-white space-x-1 flex items-center text-xs'>
                            Download Sample File
                        </button>
                    </div>
                    <form>
                        <label for="file-input" className="sr-only">Choose CSV file</label>
                        <input
                            type="file" accept=".csv" onChange={handleFileUpload}
                            className="block w-full border border-gray-200 shadow-sm rounded-lg text-sm disabled:opacity-50 disabled:pointer-events-none file:bg-gray-100 file:border-0 file:me-4 file:py-3 file:px-4 " />
                        <p className="mt-1 text-sm text-gray-500 " id="file_input_help">

                        </p>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <button onClick={onClose} className='bg-red-500 px-2 py-1.5 mx-2 rounded text-white space-x-1 flex items-center text-xs'>
                        Cancel
                    </button>
                    <button onClick={handleCsvSubmit} disabled={disabled || csvData.length === 0} type='submit' className='bg-[#3A3D89] px-3 py-1.5 rounded text-white space-x-1 flex items-center justify-center text-xs'>
                        Add
                        {loading && (
                             <ProcessingLogo textColor='text-white' />
                        )}
                    </button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default UploadTargets