import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import VulnerabilityBadge from 'components/badges/VulnerabilityBadge';
import PageTitle from 'components/logic/PageTitle';
import AscendingSortLink from 'components/ui/AscendingSortLink';
import Breadcrumb from 'components/ui/Breadcrumb';
import CreateButton from 'components/ui/buttons/Create';
import DescendingSortLink from 'components/ui/DescendingSortLink';
import Loading from 'components/ui/Loading';
import NoResults from 'components/ui/NoResults';
import Title from 'components/ui/Title';
import useDelete from 'hooks/useDelete';
import useFetch from 'hooks/useFetch';
import secureApiFetch from 'services/api';

import VulnerabilityCategorySpan from '../categories/Span';

const VulnerabilityTemplatesList = () => {
    const navigate = useNavigate();
    const [sortBy, setSortBy] = useState({ column: 'insert_ts', order: 'DESC' })
    const [templates, updateTemplates] = useFetch(`/vulnerabilities?isTemplate=1&orderColumn=${sortBy.column}&orderDirection=${sortBy.order}`)

    const cloneVulnerability = (ev, templateId) => {
        ev.stopPropagation();

        secureApiFetch(`/vulnerabilities/${templateId}/clone`, { method: 'POST' })
            .then(resp => resp.json())
            .then(data => {
                navigate(`/vulnerabilities/${data.vulnerabilityId}/edit`);
            });
    }

    const onSortChange = (ev, column, order) => {
        ev.preventDefault();

        setSortBy({ column: column, order: order });
    }

    const viewTemplate = (templateId) => {
        navigate(`/vulnerabilities/templates/${templateId}`);
    }

    const destroy = useDelete('/vulnerabilities/', updateTemplates);

    const deleteTemplate = (ev, templateId) => {
        ev.stopPropagation();

        destroy(templateId);
    }

    const onAddVulnerabilityTemplateClick = () => {
        navigate(`/vulnerabilities/create?isTemplate=true`)
    }

    return (
        <>
            <PageTitle value="Vulnerability templates" />
            <div className='heading'>
                <Breadcrumb>
                    <Link to="/vulnerabilities">Vulnerabilities</Link>
                    <span>Templates</span>
                </Breadcrumb>

                <CreateButton onClick={onAddVulnerabilityTemplateClick}>Add vulnerability template</CreateButton>
            </div>
            <Title title='Vulnerability templates' icon={<ArticleOutlinedIcon />} />
            {!templates
                ? <Loading />
                : <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded mt-2">
                    <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                        <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                            <tr>
                                <th scope="col" className="px-1 py-2">Summary</th>
                                <th scope="col" className="px-1 py-2">
                                    <DescendingSortLink callback={onSortChange} property="category_name" /> Category <AscendingSortLink callback={onSortChange} property="category_name" />
                                </th>
                                <th scope="col" className="px-1 py-2 text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {templates.length === 0 ?
                                <tr><td colSpan={3}><NoResults /></td></tr>
                                :
                                templates.map((template) =>
                                    <tr key={template.id} onClick={() => viewTemplate(template.id)} className="bg-white border-b border-gray-300 text-black font-light">
                                        <td scope="row" className="px-1 py-2 "><VulnerabilityBadge vulnerability={template} /></td>
                                        <td scope="row" className="px-1 py-2 "><VulnerabilityCategorySpan name={template.category_name} parentName={template.parent_category_name} /></td>
                                        <td scope="row" className="px-1 py-2 text-center">
                                            <span className='flex space-x-3 items-center'>
                                                <button onClick={ev => cloneVulnerability(ev, template.id)} className='bg-[#3A3D89] px-2 py-1.5 rounded font-medium text-white text-xs'>
                                                    Clone and edit
                                                </button>

                                                <Link to={`/vulnerabilities/${template.id}/edit`}>
                                                    <EditOutlinedIcon className='text-[#3A3D89]' fontSize='small' />
                                                </Link>
                                                <button onClick={ev => deleteTemplate(ev, template.id)} >
                                                    <DeleteIcon className='text-[#EB1F36]' fontSize='small' />
                                                </button>
                                            </span>
                                        </td>

                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            }
        </>
    )
}

export default VulnerabilityTemplatesList;
