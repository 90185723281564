import { KeycloakConfig } from "keycloak-js";

const Configuration = {

    isSecureTransportEnabled: (): string => window.env.REACT_APP_SECURE_TRANSPORT_ENABLED || 'true',

    getDefaultApiUrl: (): string => window.env.REACT_APP_DEFAULT_API_URL || 'https://api.prosecurity.work' ,

    getNotificationsServiceHostPort: (): string => window.env.REACT_APP_NOTIFICATIONS_SERVICE_HOST_PORT || 'agent-projects.prosecurity.work',

    getAgentServiceHostPort: (): number => window.env.REACT_APP_AGENT_SERVICE_HOST_PORT ||  'agent-projects.prosecurity.work',

    getContextPath: (): string => window.env.REACT_APP_CONTEXT_PATH || '/',

    getLogoUrl: (): string => window.env.REACT_APP_LOGO_URL || '/logo-name.svg',

    getAppVersion: (): string => window.env.APP_VERSION || process.env.PATH || 'Default 1.0.0',

    getKeycloakConfig: (): KeycloakConfig => window.env.REACT_APP_KEYCLOAK_CONFIG || {
        url: 'https://auth.prosecurity.work',
        realm: 'prosecurity',
        clientId: 'web-client'
    }
};

export default Configuration;
