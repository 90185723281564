import Badge from 'components/badges/Badge';
import UserRoleBadge from 'components/badges/UserRoleBadge';
import Ipv4Link from 'components/ui/Ipv4Link';
import NoResultsTableRow from 'components/ui/tables/NoResultsTableRow';
import UserAgentLabel from 'components/ui/UserAgentLabel';
import UserLink from 'components/users/Link';

const AuditLogsTable = ({ auditLog, hideUserColumns = false }) => {
    const numColumns = hideUserColumns ? 4 : 6;

    return (
        <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
            <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                    <tr>
                        <th scope="col" className="px-2 py-2">Event</th>
                        <th scope="col" className="px-2 py-2">IP address</th>
                        {!hideUserColumns &&
                            <th scope="col" className="px-2 py-2 w-28 text-center">User Location</th>
                        }

                        <th scope="col" className="px-2 py-2 w-28">User agent</th>
                        <th scope="col" className="px-2 py-2 w-28">Date / Time</th>
                        {!hideUserColumns &&
                            <>
                                <th scope="col" className="px-2 py-2">User</th>
                                <th scope="col" className="px-2 py-2">Role</th>
                            </>
                        }
                        <th scope="col" className="px-2 py-2">Data</th>
                    </tr>
                </thead>
                <tbody>
                    {auditLog !== null && auditLog.length === 0 && <NoResultsTableRow numColumns={numColumns} />}
                    {auditLog !== null && auditLog.map(entry => {
                        return (
                            <tr key={entry.id} className="bg-white border-b border-gray-300 text-black font-medium">
                                <td className="px-2 py-2 ">
                                    <Badge>{entry.action}</Badge>
                                </td>
                                <td className="px-2 py-2 "><Ipv4Link value={entry.client_ip} /></td>
                                {!hideUserColumns &&
                                    <td className="px-2 py-2 text-center">
                                        {entry.user_location || 'NA'}
                                    </td>
                                }

                                <td className="px-2 py-2 ">{entry.user_agent ? <UserAgentLabel userAgent={entry.user_agent} /> : '-'}</td>
                                <td className="px-2 py-2 ">{entry.insert_ts}</td>
                                {!hideUserColumns &&
                                    <>
                                        <td className="px-2 py-2 ">{entry.user_name ?
                                            <UserLink userId={entry.user_id}>{entry.user_name}</UserLink> : '-'}</td>
                                        <td className="px-2 py-2 "><UserRoleBadge role={entry.user_role} /></td>
                                    </>
                                }
                                <td className="px-2 py-2 ">
                                    {JSON.stringify(entry.object)}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default AuditLogsTable;
