import EmptyField from "components/ui/EmptyField";
import ReactMarkdown from "react-markdown";

const VulnerabilityRemediationPanel = ({ vulnerability }) => {
    return <div className="grid grid-two">
        <div>
            <h4 className="text-sm font-medium">Remediation instructions</h4>
            {vulnerability.remediation ? <ReactMarkdown>{vulnerability.remediation}</ReactMarkdown> : <EmptyField />}
        </div>

        <div>
            <h4 className="text-sm font-medium">Properties</h4>
            <dl> 
                <dt>Priority</dt>
                <dd>{vulnerability.remediation_priority ?? <EmptyField />}</dd>

                <dt>Complexity</dt>
                <dd>{vulnerability.remediation_complexity ?? <EmptyField />}</dd>
            </dl>
        </div>
    </div>
}

export default VulnerabilityRemediationPanel;
