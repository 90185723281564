import useFetch from "hooks/useFetch";
import Risks from "models/Risks";
import { useNavigate } from "react-router-dom";

const VulnerabilityFilters = ({ tableModel, tableModelSetter: setTableModel, showProjectFilter = true }) => {
    const [categories] = useFetch('/vulnerabilities/categories?parentsOnly=true');
    const [projects] = useFetch('/projects?status=active');
    const navigate= useNavigate()

    const onFilterChange = ev => {
        const newFilters = { ...tableModel.filters, [ev.target.name]: ev.target.value };
        setTableModel({ ...tableModel, filters: newFilters });
    
        const queryParams = new URLSearchParams();
        queryParams.set('page', 1); 
        Object.keys(newFilters)
            .forEach(key => newFilters[key] !== null && newFilters[key].length !== 0 && queryParams.append(key, newFilters[key]));
        const url = `/vulnerabilities?${queryParams.toString()}`;
        navigate(url);
    }
    

    return (
        <div className="flex w-full space-x-3 px-3 pb-2 rounded-lg justify-end">
            {showProjectFilter &&
                <select name="projectId" onChange={onFilterChange} className="px-2 py-1 border rounded text-sm max-w-32">
                    <option value=''>Project</option>
                    {projects !== null && projects.map(project => <option key={project.id} value={project.id}>{project.name}</option>)}
                </select>
            }

            <select name="status" onChange={onFilterChange} className="px-2 py-1 border rounded text-sm">
                <option value=''>Status</option>
                <option value='Open'>Open</option>
                <option value='Closed'>Closed</option>
            </select>

            <select name="risk" onChange={onFilterChange} className="px-2 py-1 border rounded text-sm">
                <option value=''>Risk</option>
                {Risks.map(risk => <option key={risk.id} value={risk.id}>{risk.name}</option>)}
            </select>

            <select name="categoryId" onChange={onFilterChange} className="px-2 py-1 border rounded text-sm max-w-32">
                <option value=''>Category</option>
                {categories !== null && categories.map(category => <option key={category.id} value={category.id}>{category.name}</option>)}
            </select>
        </div>
    )
}

export default VulnerabilityFilters;
