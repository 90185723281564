
// Fear not, we also check these permissions on the server side.
const UserPermissions = {
    administrator: [
        '*.*'
    ],
    superuser: [
        'vulnerabilities.*',
        'commands.*',
        'tasks.*',
        'projects.*',
        'clients.*',
        'notes.*',
        'targets.*',
        'attachment.*'
    ],
    user: [
        'vulnerabilities.*',
        'commands.*',
        'tasks.list',
        'projects.list',
        'users.login',
        'notes.*',
        'attachment.*'
    ],
    client: [
        'projects.list',
        'users.login',
        'clients.self',
        'tasks.list',
        'notes.*',
        'attachment.list'
    ]
}

export default UserPermissions;
