const VulnerabilityStatuses = [
    { id: 'open-reported', name: 'Open (reported)' },
    { id: 'open-unresolved', name: 'Open (unresolved)' },
    { id: 'confirmed-unexploited', name: 'Confirmed (unexploited)' },
    { id: 'confirmed-exploited', name: 'Confirmed (exploited)' },
    { id: 'resolved-remediated', name: 'Resolved (remediated)' },
    { id: 'resolved-mitigated', name: 'Resolved (mitigated)' },
    { id: 'closed-remediated', name: 'Closed (remediated)' },
    { id: 'closed-mitigated', name: 'Closed (mitigated)' },
    { id: 'closed-accepted', name: 'Closed (Accepted)' },
    // { id: 'closed-rejected', name: 'Closed (rejected)' },
];

export default VulnerabilityStatuses;
