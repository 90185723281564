
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { actionCompletedToast, errorToast } from "components/ui/toast";
import { useRef, useState } from "react";
import secureApiFetch from "services/api";

const ReportModalDialog = ({ isOpen, onSubmit, onCancel }) => {
    const fileRef = useRef();

    const emptyReportTemplate = {
        version_name: "",
        version_description: null,
        resultFile: null,
    }
    const [reportTemplate, setReportTemplate] = useState(emptyReportTemplate)

    const onCreateReportFormSubmit = ev => {
        ev.preventDefault();

        const formData = new FormData();
        formData.append('version_name', reportTemplate.version_name);
        formData.append('version_description', reportTemplate.version_description);
        formData.append('resultFile', fileRef.current.files[0]);

        secureApiFetch(`/reports/templates`, { method: 'POST', body: formData })
            .then((res) => {
                onSubmit();
                if (res.status === 200) {
                    actionCompletedToast(`The report template "${reportTemplate.version_name}" has been added.`);
                } else {
                    errorToast('Missing docx or xlsx file or You can upload only docx or xlsx file');
                }
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setReportTemplate(emptyReportTemplate)
            })
    }

    const onFormChange = ev => {
        setReportTemplate({ ...reportTemplate, [ev.target.name]: ev.target.value })
    }

    return <Modal size="xl" isOpen={isOpen} onClose={onCancel}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader><h4>New report template details</h4></ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <form id="reportTemplateForm" onSubmit={onCreateReportFormSubmit}>
                    <label className='font-semibold text-sm'>Version Name</label>
                    <input type="text" name='version_name' onChange={onFormChange} placeholder='Add version name' className="p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50" />

                    <label className='font-semibold text-sm'>Version Description</label>
                    <input type="text" name="version_description" onChange={onFormChange} placeholder='Add description' className="p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50" />

                    <label className='font-semibold text-sm'>File</label>
                    <input type="file" name="resultFile" ref={fileRef} onChange={onFormChange} className="p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50" />

                </form>
            </ModalBody>

            <div className='flex  space-x-2 justify-end px-2'>
                <button onClick={onCancel} className='bg-[#3A3D89] px-4 py-2 rounded text-white text-sm'>
                    Cancel
                </button>
                <button onClick={onCreateReportFormSubmit} className='bg-[#3A3D89] px-4 py-2 rounded text-white text-sm'>
                    Save
                </button>
            </div>
        </ModalContent>
    </Modal>
}

export default ReportModalDialog;
