import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import ReactMde from 'react-mde';

const MarkdownEditor = ({ name: editorName, value, onChange: onFormChange }) => {

    const [selectedTab, setSelectedTab] = useState('write');

    return (
        <div className=''>
            <ReactMde
                value={value}
                onChange={editorValue => onFormChange({ target: { name: editorName, value: editorValue } })}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={markdown =>
                    Promise.resolve(<ReactMarkdown >{markdown}</ReactMarkdown>)
                }

            />
        </div>
    )
}

export default MarkdownEditor;
