import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import BugReportIcon from '@mui/icons-material/BugReport';
import RestrictedComponent from 'components/logic/RestrictedComponent';
import CreateButton from 'components/ui/buttons/Create';
import VulnerabilityFilters from 'components/vulnerabilities/Filters';
import VulnerabilityTableModel from 'components/vulnerabilities/VulnerabilityTableModel';
import secureApiFetch from 'services/api';

import VulnerabilitiesTable from '../vulnerabilities/VulnerabilitiesTable';
import useQuery from 'hooks/useQuery';
import PaginationVuln from '../layout/PaginationVuln';

const ProjectVulnerabilities = ({ project }) => {
    const navigate = useNavigate();
    const query = useQuery();
    let initialPageNumber = query.get('page');
    initialPageNumber = initialPageNumber !== null ? parseInt(initialPageNumber) : 1;

    const [tableModel, setTableModel] = useState(new VulnerabilityTableModel())
    
    const [totalCount, setTotalCount] = useState('?');
    const [numberPages, setNumberPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(initialPageNumber);

    const handlePrev = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < numberPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const fetchVulnerabilities = useCallback(() => {
        const queryParams = new URLSearchParams();
        queryParams.set('projectId', project.id);
        queryParams.set('isTemplate', false);
        queryParams.set('page', currentPage - 1); 
        queryParams.set('orderColumn', tableModel.sortBy.column);
        queryParams.set('orderDirection', tableModel.sortBy.order);
        Object.keys(tableModel.filters)
            .forEach(key => tableModel.filters[key] !== null && tableModel.filters[key].length !== 0 && queryParams.append(key, tableModel.filters[key]));
        const url = `/vulnerabilities?${queryParams.toString()}`;
        secureApiFetch(url, { method: 'GET' })
            .then(resp => {
                if (resp.headers.has('X-Page-Count')) {
                    setNumberPages(resp.headers.get('X-Page-Count'))
                }
                if (resp.headers.has('X-Total-Count')) {
                    setTotalCount(resp.headers.get('X-Total-Count'))
                }
                return resp.json()
            })
            .then(data => {
                setTableModel(tableModel => ({ ...tableModel, vulnerabilities: data }));
            })
    }, [setTableModel,currentPage, tableModel.filters, tableModel.sortBy.column, tableModel.sortBy.order, project])

    const handleCreateVulnerability = () => {
        navigate(`/vulnerabilities/create?projectId=${project.id}`)
    }

    useEffect(() => {
        fetchVulnerabilities();
    }, [fetchVulnerabilities, tableModel.filters]);

    return (
        <section className='-mt-1'>
            <h4 className='text-sm font-medium mb-2'>
                <BugReportIcon className='text-[#3A3D89]' /> Vulnerabilities ({totalCount})
            </h4>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
                <div className='flex space-x-2'>
                    <VulnerabilityFilters tableModel={tableModel} tableModelSetter={setTableModel} showProjectFilter={false} />
                </div>

                <PaginationVuln page={currentPage} total={numberPages} handlePrev={handlePrev} handleNext={handleNext} />

                <RestrictedComponent roles={['administrator', 'superuser', 'user']}>
                    <CreateButton onClick={handleCreateVulnerability}>Add new vulnerability</CreateButton>
                </RestrictedComponent>
            </div>
            <VulnerabilitiesTable tableModel={tableModel} tableModelSetter={setTableModel} reloadCallback={fetchVulnerabilities} showProjectColumn={false} showSelection={false} />
        </section >
    )
}

export default ProjectVulnerabilities;
