import React from 'react'

function UserManual() {
  return (
    <div className="px-2">
      <div className="font-medium text-2xl ">Help and Support</div>
      <hr className='my-2' />
      <h5>Understanding Vulnerability Status</h5>
      <div className=" font-medium mr-1 mb-1 ">
        Welcome to our guide on navigating vulnerability statuses within our Penetration Testing as a Service
        (PTaaS) Platform. As part of our commitment to providing you with comprehensive cybersecurity
        solutions, this article is designed to help you understand the lifecycle of a vulnerability within our
        system and how you can effectively respond to ensure your network remains secure.
      </div>

      <h5>Introduction to Vulnerability Statuses</h5>
      <div className=" font-medium mr-1 mb-1">
        In our portal, vulnerabilities detected during tests are assigned statuses that reflect their current state
        from discovery to resolution. These statuses are crucial for prioritizing actions and understanding the
        threat level. Here’s what each status means, and the actions required from your end.
      </div>

      {/* <!-- Status 1: Open - Report --> */}
      <div className="mt-4">1. <span className="text-red-500 font-medium">Open</span> - Report</div>
      <div className=" font-medium mt-3 mr-1 mb-1 ">
        <span className="font-bold">What It Means:</span> This is the initial status of any newly discovered vulnerability. It indicates that the issue
        has been documented, but not yet published or fully assessed.
      </div>
      <div className=" font-medium mt-3 mr-1 mb-1 ">
        <span className="font-bold">Your Role:</span> No immediate action is required from you at this stage. Our internal Quality Assurance (QA)
        or penetration testing team will be reviewing the vulnerability for further classification.
      </div>

      {/* <!-- Status 2: Confirmed - Unexploited/Exploited --> */}
      <div className="mt-4">2. <span className="text-red-500 font-medium">Confirmed</span> - Unexploited/Exploited</div>
      <div className=" font-medium mt-3 mr-1 mb-1 ">
        <span className="font-bold">What It Means:</span> After initial assessment, vulnerabilities are classified as &apos;Confirmed.&apos; If it&apos;s marked
        &apos;Unexploited,&apos; it means the vulnerability exists but hasn&apos;t been abused. Conversely, &apos;Exploited&apos; means
        there is evidence the vulnerability has been used maliciously.
      </div>
      <div className=" font-medium mt-3 mr-1 mb-1 ">
        <span className="font-bold">Your Role:</span> This stage requires your attention. You&apos;ll need to log into the client portal and decide
        whether to mitigate (reduce the risk) or remediate (completely resolve) the vulnerability. Our system
        provides detailed guidance on possible actions.
      </div>

      {/* <!-- Status 3: Resolved - Mitigated/Remediated --> */}
      <div className="mt-4">3. <span className="text-red-500 font-medium">Resolved</span> - Mitigated/Remediated</div>
      <div className=" font-medium mt-3 mr-1 mb-1 ">
        <span className="font-bold">What It Means:</span> Once you&apos;ve taken action, the status changes to &apos;Resolved,&apos; indicating that steps have
        been taken to address the vulnerability. It can be either &apos;Mitigated&apos; if the risk is reduced or
        &apos;Remediated&apos; if the issue is fully resolved.
      </div>
      <div className=" font-medium mt-3 mr-1 mb-1 ">
        <span className="font-bold">Your Role:</span> Having already taken the necessary steps, you can monitor the status and await
        confirmation from the QA or pen test team.
      </div>

      {/* <!-- Status 4: Closed - Remediated/Mitigated/Accepted --> */}
      <div className="mt-4">4. <span className="text-red-500 font-medium">Closed</span> - Remediated/Mitigated/Accepted</div>
      <div className=" font-medium mt-3 mr-1 mb-1 ">
        <span className="font-bold">What It Means:</span> Closure statuses are good news, meaning the vulnerability is effectively dealt with.
        &apos;Closed - Remediated&apos; signifies a full resolution, &apos;Closed - Mitigated&apos; indicates acceptable risk reduction,
        and &apos;Closed - Accepted&apos; means the risk is acknowledged with no further action planned.
      </div>
      <div className=" font-medium mt-3 mr-1 mb-1 ">
        <span className="font-bold">Your Role:</span> No further action is required. You can review the final reports and documentation for your
        records and compliance needs.
      </div>

      {/* <!-- Status 5: Open - Unresolved --> */}
      <div className="mt-4">5. <span className="text-red-500 font-medium">Open</span> - Unresolved</div>
      <div className=" font-medium mt-3 mr-1 mb-1 ">
        <span className="font-bold">What It Means:</span> If a vulnerability is found to be inadequately addressed upon retesting, it will revert
        to &apos;Open - Unresolved.&apos; This indicates that the issue persists and requires additional attention.
      </div>
      <div className=" font-medium mt-3 mr-1 mb-1 ">
        <span className="font-bold">Your Role:</span> You need to revisit the vulnerability in the client portal and take further action to ensure it
        is adequately addressed.
      </div>

      <h5>Taking Action: Your Client Portal</h5>
      <div className=" font-medium mr-1 mb-1 ">
        Our client portal is designed to provide you with a user-friendly interface to manage and respond to
        vulnerabilities. Here you can:
      </div>
      <ul className="list-disc list-inside line-height-3">
        <li>View detailed information about each vulnerability.</li>
        <li>Take action to mitigate or remediate issues.</li>
        <li>Communicate directly with our QA or pen test team.</li>
        <li>Access reports and historical data for trend analysis and compliance.</li>
      </ul>

      <h5>Conclusion</h5>
      <div className=" font-medium mr-1 mb-1 ">
        Understanding the various statuses of vulnerabilities in our PTaaS platform is key to maintaining robust
        security. By staying informed and responsive, you can ensure that vulnerabilities are effectively
        managed and resolved. Remember, our team is always here to support you through each step of the
        process, from detection to resolution.
      </div>
      <div className=" font-medium mt-2 mr-1 mb-1 ">
        For further assistance or any questions regarding your vulnerabilities, please don&apos;t hesitate to contact
        our support team through the client portal. Your security is our priority, and we are dedicated to
        helping you maintain a resilient and secure digital environment.
      </div>

      <div className="flex justify-center align-items-center mt-4">
        <img src={`/vulneability_status.png`} alt="Security Image" className="w-96" />
      </div>
    </div>
  )
}

export default UserManual