import useFetch from "hooks/useFetch";

const ProjectFilters = ({ tableModel, tableModelSetter: setTableModel }) => {
    const [clients] = useFetch('/clients')

    const onFilterChange = ev => {
        setTableModel({ ...tableModel, filters: { ...tableModel.filters, [ev.target.name]: ev.target.value } });
    }
    const projectStatusMap = ['To Start', 'On Hold', 'In Progress', 'Completed']
    // const projectSubStatusMap = ['Onboarding And Initial Setup', 'Testing', 'Remediation', 'Retest', 'QA And Reporting', 'Outside Time Window', 'Connectivity', 'Credentials', 'With Customer', 'With Assessor', 'Final Report Released', 'Archived']
    const orderChange = [
        { name: 'Updated Time Stamp', value: 'update_ts' },
        { name: 'Insert Time Stamp', value: 'insert_ts' },
        { name: 'Project Status', value: 'project_status' },
        { name: 'Name', value: 'name' },
        { name: 'Engagement Start Date', value: 'engagement_start_date' },
        { name: 'Client ID', value: 'client_id' }
    ]

    return (
        <div className="flex w-full space-x-3 px-3 py-2 rounded-lg justify-end">

            <select name="clientId" onChange={onFilterChange} placeholder='Select Client' className="px-2 py-1 border rounded text-sm">
                <option value=''>All Clients</option>
                {null !== clients && clients
                    .filter((project, index, array) => array.findIndex((p) => p.name === project.name) === index)
                    .map((client) => (
                        <option key={client.name} value={client.id}>{client.name}</option>
                    ))}
            </select>

            <select name="projectStatus" onChange={onFilterChange} placeholder='Select Status' className="px-2 py-1 border rounded text-sm">
                <option value=''>All Status</option>
                {projectStatusMap.map(statusName => (
                    <option value={statusName}> {statusName}</option>
                ))}
            </select>

            {/* <select name="projectSubStatusMap" onChange={onFilterChange} placeholder='Select Sub-Status' className="px-2 py-1 border rounded text-sm">
                <option value=''>All Sub-Status</option>
                {projectSubStatusMap.map(statusName => (
                    <option value={statusName}> {statusName}</option>
                ))}
            </select> */}

            <select name="orderColumn" onChange={onFilterChange} placeholder='Select Order By' className="px-2 py-1 border rounded text-sm">
                {orderChange.map(statusName => (
                    <option value={statusName.value}> {statusName.name}</option>
                ))}
            </select>

        </div>
    )
}

export default ProjectFilters;