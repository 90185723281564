import { Stat, StatGroup, StatLabel, StatNumber } from "@chakra-ui/react";

import DataUsageOutlinedIcon from '@mui/icons-material/DataUsageOutlined';
import FileSizeSpan from "components/ui/FileSizeSpan";
import Loading from "components/ui/Loading";
import useFetch from "hooks/useFetch";

import Breadcrumb from "../ui/Breadcrumb";
import Title from "../ui/Title";
import { Link } from "react-router-dom";

const SystemUsagePage = () => {
    const [usage] = useFetch('/system/usage');

    if (!usage) return <Loading />

    return (
        <div>
            <div className='heading'>
                <Breadcrumb>
                    <Link to='/system'> System </Link>
                    <div>Usage</div>
                </Breadcrumb>
            </div>
            <Title type="System" title="Usage" icon={<DataUsageOutlinedIcon />} />

            <StatGroup>
                <Stat>
                    <StatLabel>Attachments count</StatLabel>
                    <StatNumber>{usage.attachments.total_count} total</StatNumber>
                </Stat>

                <Stat>
                    <StatLabel>Attachments total disk usage</StatLabel>
                    <StatNumber><FileSizeSpan fileSize={usage.attachments.total_file_size} /></StatNumber>
                </Stat>
            </StatGroup>
        </div>
    )
};

export default SystemUsagePage;
