import { useNavigate } from 'react-router-dom';
import { Switch } from "@chakra-ui/react";

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ProjectBadge from "components/projects/ProjectBadge";
import SecondaryButton from "components/ui/buttons/Secondary";
import DeleteIcon from '@mui/icons-material/Delete';
import RelativeDateFormatter from "components/ui/RelativeDateFormatter";
import NoResultsTableRow from "components/ui/tables/NoResultsTableRow";
import useDelete from "hooks/useDelete";
import PropTypes from 'prop-types';
import secureApiFetch from "services/api";
import DownloadFromS3 from "services/DownloadFromS3";

const ReportsTable = ({ reports, updateReports, includeProjectColumn = false }) => {

    const navigate = useNavigate();

    const deleteReport = useDelete('/reports/', updateReports);

    const handleDownload = (reportId) => {
        DownloadFromS3(reportId)
    }

    const toggleVisibility = (event, id) => {
        event.preventDefault();

        const newVisibility = reports.find((report) => report.id === id)?.visibility === 'private' ? 'public' : 'private';

        secureApiFetch(`/reports/${id}/release`, {
            method: 'PATCH',
            body: JSON.stringify({ visibility: newVisibility }),
        })
            .then(() => {
                updateReports((prevReports) => {
                    return prevReports.map((report) => {
                        if (report.id === id) {
                            return { ...report, visibility: newVisibility };
                        }
                        return report;
                    });
                });
            })
            .catch((error) => {
                console.error('Error updating visibility:', error);
            });
    };



    const handleSendByEmail = (projectId) => {
        navigate(`/projects/${projectId}/report/send`);
    }

    return (
        <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
            <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                    <tr>
                        <th scope="col" className="px-2 py-2">Name (Description)</th>
                        {includeProjectColumn &&
                            <th scope="col" className="px-2 py-2">Project</th>
                        }
                        <th scope="col" className="px-2 py-2 text-center min-w-24">Client Name</th>
                        <th scope="col" className="px-2 py-2 text-center">Date Time</th>
                        <th scope="col" className="px-2 py-2 text-center min-w-24">Report Type</th>
                        <th scope="col" className="px-2 py-2 text-center">Visibility</th>
                        <th scope="col" className="px-2 py-2 ">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {reports.length === 0 && <NoResultsTableRow numColumns={7} />}
                    {reports.map((report, index) => {
                        return (
                            <tr key={index} className="bg-white border-b border-gray-300 text-gray-900">

                                <th scope="row" className="px-2 py-2 font-medium">
                                    <SecondaryButton onClick={() => handleDownload(report.attachment_id)}>
                                        {report.version_name} ({report.version_description})
                                    </SecondaryButton>
                                </th>

                                {includeProjectColumn &&
                                    <th scope="row" className="px-1 py-2 ">
                                        <ProjectBadge project={{ id: report.project_id, name: report.project_name }} />
                                    </th>}

                                <th scope="row" className="px-2 py-2 font-medium text-center">
                                    {report.client_name}
                                </th>

                                <th scope="row" className="px-2 text-center py-2 font-medium">
                                    <RelativeDateFormatter date={report.insert_ts} />
                                </th>

                                <th scope="row" className="px-2 py-2 text-center">
                                    <SecondaryButton onClick={() => handleDownload(report.attachment_id)}>
                                        {report.file_mimetype === "application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? 'Technical' : 'Executive'}
                                        <FileDownloadOutlinedIcon fontSize='small' />
                                    </SecondaryButton>
                                </th>

                                <th scope="row" className="px-2 py-2 text-center">
                                    {report.visibility !== 'private' ? <span className="bg-[#48A14D] text-white text-xs font-medium me-2 px-2 py-0.5 rounded">
                                        Published
                                    </span> : <span className="bg-red-500 text-white text-xs font-medium me-2 px-2 py-0.5 rounded">
                                        Private
                                    </span>}
                                </th>

                                <th scope="row" className="px-2 py-2 ">
                                    <span className="flex items-center space-x-1">
                                        <Switch isChecked={report.visibility !== 'private'} onChange={(event) => toggleVisibility(event, report.id)} size='sm' />
                                        <button onClick={() => handleSendByEmail(report.project_id)} className='bg-[#3A3D89] px-2 py-1 rounded text-white text-xs'>
                                            Send
                                        </button>
                                        <button onClick={() => deleteReport(report.id)}>
                                            <DeleteIcon fontSize='small' className='text-[#EB1F36]' />
                                        </button>
                                    </span>
                                </th>

                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

ReportsTable.propTypes = {
    reports: PropTypes.array.isRequired,
    includeProjectColumn: PropTypes.bool
};

export default ReportsTable;
